import request from '@/router/axios';

export const getList = (data) => request({
  url: '/api/blade-handheld-hospital/outpatient/getInspectionList',
  method: 'post',
  data,
});

//获取检查详情
export const getInspectionDetail = (data) => request({
  url: '/api/blade-handheld-hospital/outpatient/getInspectionDetail',
  method: 'post',
  data
})